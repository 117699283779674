var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"8Tk-O1pMYskY9yAsxzvxp"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

const enabled = process.env.NEXT_PUBLIC_SENTRY_ENABLED
const env = process.env.NEXT_PUBLIC_SENTRY_ENV
const sampleRate = parseFloat(process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE)

if (enabled === 'true') {
  Sentry.init({
    dsn: 'https://18461bf4be2341379131a7ff9b919cdb@o115862.ingest.sentry.io/6587749',
    sampleRate: sampleRate,
    environment: env,
    debug: env === 'dev',
  })
}
